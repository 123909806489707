import { useState } from "react";
import { Commune } from "../commune-interface";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemSecondaryAction from "@mui/material/ListItemSecondaryAction";
import ListSubheader from '@mui/material/ListSubheader';
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";

export default function ListComponent({ communes }: { communes: Commune[] }) {
  const [order, setOrder] = useState("time");
  const communesFounds = communes.filter((c) => !!c.timeFound);

  return (
    <List>
        <ListSubheader component="div" id="nested-list-subheader">
          {communesFounds.length} Commune trouvées
        </ListSubheader>
      {communesFounds
        .sort((a, b) => {
          if (order === "nom") {
            if (a.name < b.name) {
              return -1;
            }
            if (a.name > b.name) {
              return 1;
            }
            return 0;
          }
          if (order === "region") {
            if (a.region < b.region) {
              return -1;
            }
            if (a.region > b.region) {
              return 1;
            }
            return 0;
          }
          if (a.timeFound! < b.timeFound!) {
            return 1;
          }
          if (a.timeFound! > b.timeFound!) {
            return -1;
          }
          return 0;
        })
        .map((commune) => (
          <ListItem key={commune.code}>
            <ListItemText primary={commune.name} secondary={commune.codesPostaux}>

            </ListItemText>
          </ListItem>
        ))}
    </List>
  );
}
