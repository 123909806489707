import uFuzzy from "@leeoniya/ufuzzy";

const chars : any = {
  'é': 'e',
  'è': 'e',
  'ç': 'c',
  '-': ' ',
  'œ': 'oe',
  "'": " ",
  "à": "a",
  'ê': 'e'
};

export function SanitizeString(name: string) : string {
  return name.replace(/[éèçà\-œê']/g, c => chars[c]);
}

const uFuzzyOptions: uFuzzy.Options = {
  intraMode: 1,
  interLft: 1,
  interRgt: 1,
};
const fuzzyInstance = new uFuzzy(uFuzzyOptions);

export function searchHayStackUtil(hayStack: string[], needle: string) : string | null {
  const searchIdxs = fuzzyInstance.filter(hayStack, needle);
  if (searchIdxs!.length === 1) {
    return hayStack[searchIdxs![0]];
  }
  return null;
};
