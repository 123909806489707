import { Commune, GeoJsonObject } from "./commune-interface";

export function FetchOpenData(
  region: string,
  isStillActive: boolean,
  setSpiner: React.Dispatch<React.SetStateAction<boolean>>,
  setCommuneData: React.Dispatch<React.SetStateAction<Commune[]>>
) {
  setSpiner(true);
  const url = GetUrl(region);
  fetch(url)
    .then((response) => response.json())
    .then((data: any[]) => {
      const commune = data.map(
        (c) =>
          ({
            name: c.nom,
            region: ConvertRegionTostring(c.epci.nom),
            population: c.population,
            surface: c.surface,
            mairiePos: {
              type: c.mairie.type,
              coordinates: c.mairie.coordinates,
            } as GeoJsonObject,
            code: c.code,
            codesPostaux: c.codesPostaux.join(", "),
            zone: { type: c.contour.type, coordinates: c.contour.coordinates } as GeoJsonObject,
          } as Commune)
      );
      if (isStillActive) {
        setCommuneData(commune);
      }
    })
    .catch((err) => {
      console.error(err);
      if (isStillActive) {
        setCommuneData([]);
      }
    })
    .finally(() => setSpiner(false));
}

function GetUrl(region: string): string {
  let base = "";
  switch (region) {
    case "Guadeloupe":
      base = "https://geo.api.gouv.fr/departements/971";
      break;
    case "Reunion":
      base = "https://geo.api.gouv.fr/departements/974";
      break;
    case "Guyane":
      base = "https://geo.api.gouv.fr/departements/973";
      break;
    default:
      base = "https://geo.api.gouv.fr/departements/972";
  }
  return (
    base +
    "/communes?fields=code,nom,population,codesPostaux,surface,contour,mairie,epci"
  );
}

function ConvertRegionTostring(regionOpenData: string): string {
  switch (regionOpenData.toLowerCase()) {
    case "ca du pays nord martinique":
      return "Nord";
    case "ca du centre de la martinique":
      return "Centre";
    case "ca de l'espace sud de la martinique":
      return "Sud";
    default:
      return regionOpenData;
  }
}
