import "./App.css";
import { Commune } from "./commune-interface";
import { FetchOpenData } from "./fetch-open-data";
import { Box, Grid } from "@mui/material";
import { useEffect, useState } from "react";
import GameInputComponent from "./game-module/game-input-component";
import ListComponent from "./game-module/result-list.component";
import MapComponent from "./map-component/map-component";
import ScoreBoardComponent from "./game-module/score-board.component";

function App() {
  const [isLoading, setIsLoading] = useState(false);
  const [communes, setCommunes] = useState<Commune[]>([]);
  const [region, setRegion] = useState("Martinique");

  useEffect(() => {
    let active = true;
    FetchOpenData(region, active, setIsLoading, setCommunes);
    return () => {
      active = false;
    };
  }, [region]);

  return (
    <Grid container>
      <Grid item sm={9}>
        <Grid
          container
          spacing={2}
          style={{
            position: "absolute",
            top: "15%",
            left: "30%",
            zIndex: 1,
            maxWidth: "280px",
          }}
        >
          <GameInputComponent
            communes={communes}
            setCommunes={setCommunes}
          ></GameInputComponent>
        </Grid>
        {communes.length !== 0 ? <MapComponent communes={communes} /> : <p>loading...</p>}        
      </Grid>
      <Grid item sm={3}>
        <ListComponent communes={communes} />
      </Grid>
    </Grid>
  );
}

export default App;
